import React, { useEffect, useState } from 'react'
import HeaderOther from '../../Components/HeaderOther/HeaderOther'
import {gallery1, gallery10, gallery11, gallery12, gallery13, gallery14, gallery15, gallery16, gallery17, gallery18, gallery19, gallery2, gallery20, gallery21, gallery22, gallery23, gallery24, gallery25, gallery26, gallery27, gallery3, gallery4, gallery5, gallery6, gallery7, gallery8, gallery9, news1, news2, news3, services3, servicesBg, whyus, whyus2 } from '../../assets/images'
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const News = () => {

  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.sossecurity.az/sossecurity/news.php`,
        { withCredentials: false }
      );
      const filteredProducts = response.data.data;
      setBlogs(filteredProducts);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const { t, i18n } = useTranslation();


  if (isLoading) {
    return <div>Loading...</div>; // Show loading indicator while data is being fetched
  }

  return (
    <> 
    <HeaderOther/>  
      <div>
    <div className='md:max-w-container max-w-containerSm mx-auto'>
    <p className='mt-[20px] mb-[30px] lg:mb-[60px] text-sm lg:text-base'><span className='text-[#DDB14C]'>{t("Ana səhifə")}</span> / {t("Xəbərlər")}</p>
    <p className='mt-[20px] text-[20px] md:text-[32px] font-semibold text-center'>{t("Xəbərlər")}</p>
 <div className='flex mb-[40px] justify-between mt-[40px]'>
 {/* <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8'>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news1} alt="Why Us"></img>
  <p className='mt-4'>{t("'SOS' mühafizə xidməti Zəfər bayramını şəhid ailələri ilə birlikdə qeyd edib")}</p>
</div>
</a>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news2} alt="Why Us"></img>
  <p className='mt-4'>{t("“Security Operation Services“ MMC “SOS” uşaq evini ziyarət edib, şəhid anaları ilə görüş keçirib")}</p>
</div>
</a>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news3} alt="Why Us"></img>
  <p className='mt-4'>{t("“Security Operation Services” MMC Gənclər Gününə həsr olunan tədbir keçirib")}</p>
</div>
</a>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news1} alt="Why Us"></img>
  <p className='mt-4'>{t("“Security Operation Services” şirkətinin əməkdaşları “YAŞAT” fonduna ianə ediblər")}</p>
</div>
</a>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news2} alt="Why Us"></img>
  <p className='mt-4'>{t("“Security Operation Services“ MMC “SOS” uşaq evini ziyarət edib, şəhid anaları ilə görüş keçirib")}</p>
</div>
</a>
<a href='/news/1'>
<div>
  <img className='h-[273px]' src={news3} alt="Why Us"></img>
  <p className='mt-4'>{t("“Security Operation Services” MMC Gənclər Gününə həsr olunan tədbir keçirib")}</p>
</div>
</a>

 </div> */}
 <div className='grid md:grid-cols-3 grid-cols-1 md:gap-10 gap-4'>
          {blogs.map((val, index) => {
            const { id, image1, title, text } = val;
            // Limit the text to 100 characters
            const truncatedTitle = t(title).length > 60 ? `${t(title).slice(0, 60)}...` : t(title);
            const truncatedText = t(text).length > 130 ? `${t(text).slice(0, 130)}...` : t(text);

            return (
              <a href={`/news/${id}`} key={index}>
                <div className='rounded-[20px] pt-1'>
                  <div className='mt-3 mb-5 ml-4 mr-4'>
                    <img className='rounded-lg h-[273px]' src={`https://www.sossecurity.az/sossecurity/${image1}`} alt={title} />
                    <div className='text mt-5'>
                      <h4 className='text-black text-base md:text-xl font-medium mb-2'>{t(truncatedTitle)}</h4>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
 </div>
    </div>
  </div>
    </>
  )
}

export default News
