import React from 'react'
import HeaderOther from '../../Components/HeaderOther/HeaderOther'
import { certificate1, certificate2, certificate3, partners1, partners10, partners11, partners12, partners13, partners14, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, press1, press2, press3, whyus, whyus2 } from '../../assets/images'
import { useTranslation } from 'react-i18next';

const Press = () => {

  const { t, i18n } = useTranslation();
  return (
    <>
     <HeaderOther/>
     <div className='md:max-w-container max-w-containerSm mx-auto'>
     <p className='mt-[20px] mb-[30px] lg:mb-[60px] text-sm lg:text-base'><span className='text-[#DDB14C]'>{t("Ana səhifə")}</span>  /  {t("Mətbuat")}</p>
     </div>
     <div className=''> 
      <div className='max-w-container mx-auto'>
          <p className='text-[24px] lg:text-[32px] text-center md:mb-[30px] lg:mb-[40px] font-semibold'>{t("Mətbuat")}</p>   
      </div> 
    </div>
    <div className='text-[black] h-auto lg:h-[120px] mb-[40px] lg:mb-[283px]'>
      <div className='md:max-w-container max-w-containerSm mx-auto'>
      <div className='md:h-[125px] grid grid-cols-1 md:grid-cols-3 md:gap-6'>
         <div className=' md:w-[397px] w-full h-[125px] flex justify-center items-center rounded-[4px]'>
         <img className='object-contain' src={press1} alt="Partner 1" />
         </div>
         <div className='md:w-[397px] w-full h-[125px] flex justify-center items-center rounded-[4px]'>
         <img className='object-contain' src={press2} alt="Partner 1" />
         </div>
         <div className='md:w-[397px] w-full h-[125px] flex justify-center items-center rounded-[4px]'>
         <img className='object-contain' src={press3} alt="Partner 1" />
         </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Press
